import React, { useState, useEffect, useLayoutEffect, useRef, Children } from 'react'
import Hammer from 'react-hammerjs'

import { SerifRarr, SerifLarr, SansRarr, SansLarr } from '~components/global/svg'

const Slider = ({ children, swipeIndicator, slidesVisible, arrows, nums, setSlide, preventDrag }) => {
  const [index, setIndex] = useState(0)
  const [points, setPoints] = useState([0])
  const trackRef = useRef()
  const sliderRef = useRef()

  let maxIndex = slidesVisible ? React.Children.count(children) - slidesVisible : React.Children.count(children) - 1
  let translateX = 0 - points[index]
  const nextSlide = () => {
    let nextIndex = index + 1 <= maxIndex ? index + 1 : maxIndex
    setIndex(nextIndex)
  }

  const prevSlide = () => {
    let prevIndex = index - 1 > 0 ? index - 1 : 0
    setIndex(prevIndex)
  }

  const getDir = (velocity, delta) => {
    let dir
    if( velocity > 0.01 || velocity < -0.01 ){
      dir = velocity < 0 ? 'left' : 'right'
    }
    else{
      dir = delta < 0 ? 'left' : 'right'
    }
    return dir
  }

  const handleDragEnd = e => {
    if(preventDrag) return null
    trackRef.current.classList.remove('kill-anim')
    let dir = getDir(e.velocityX, e.deltaX)
    if(index === maxIndex){
      // we're at the end
      if(dir === 'left'){
        trackRef.current.style.transform = `translate3d(${translateX}px, 0, 0)`
      }
      else{
        prevSlide()
      }
    }
    if(index === 0){
      // we're at the start
      if(dir === 'right'){
        trackRef.current.style.transform = `translate3d(${translateX}px, 0, 0)`
      }
      else{
        nextSlide()
      }
    }
    else if(dir === 'left'){
      nextSlide()
    }
    else{
      prevSlide()
    }
  }

  const handleDrag = e => {
    //fix ios scroll bug
    let isActualDrag = e.changedPointers[0].type !== "pointercancel"
    if(isActualDrag && !preventDrag){
      trackRef.current.classList.add('kill-anim')
      trackRef.current.style.transform = `translate3d(${translateX + e.deltaX}px,0,0)`
    }
  }

  const handleResize = () => {
    let width = sliderRef.current.clientWidth
    updatePoints()
  }

  const updatePoints = () => {
    const nodes = [...trackRef.current.childNodes]
    let newPoints = nodes.map(n => n.offsetLeft)

    //check that the first slide doesn't have an offset (because ios detects the offset relative to the window)
    let initialOffset = newPoints[0]
    if(initialOffset !== 0){
      newPoints = newPoints.map(p => p - initialOffset)
    }
    setPoints(newPoints)
  }

  useEffect(() => {
    if(setSlide >= 0){
      setIndex(setSlide)
    }
  })

  useLayoutEffect(() => {
    const width = sliderRef.current.clientWidth
    updatePoints()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return(
    <>
    <Hammer onPan={e => handleDrag(e)} onPanEnd={e => handleDragEnd(e)} >
      <div className="slider-wrap">
        <div className="slider" ref={sliderRef}>
            <div className="slider__track" ref={trackRef} style={{
              transform: `translate3d(${translateX}px, 0, 0)`
            }}>
              {children}
            </div>
            {swipeIndicator &&
            <div className={index === maxIndex ? 'slider__swipe-indicator slider__swipe-indicator--hidden' : 'slider__swipe-indicator'}>
              <span>
                Swipe right
              </span>
              <SerifRarr />
            </div>
            }
        </div>
      </div>
    </Hammer>
    <div className="slider-controls">
      {nums &&
        <div className="slider-nums">
          {index + 1} of {Children.count(children)}
        </div>
      }
      {arrows &&
        <div className="slider-arrows">
          <button className={`slider-prev ${index === 0 ? 'slider-prev--inactive' : ''}`} onClick={() => prevSlide()}>
            {arrows === "sans" ? <SansLarr /> : <SerifLarr />}
          </button>
          <button className={`slider-next ${index === maxIndex ? 'slider-next--inactive' : ''}`} onClick={() => nextSlide()}>
            {arrows === "sans" ? <SansRarr /> : <SerifRarr />}
          </button>
        </div>
      }
    </div>
    </>
  )
}

export default Slider
